.prox-eventos{
font-size: 18px;
font-family: Montserrat-Light;
color: #981E97;
padding: 20px 0 0 20px;
margin: 0;
}

.agregar-evento{
  font-size: 18px;
  font-family: Montserrat-Light;
  color: #981E97;
  padding: 0;
  margin: 0;
}

.seccion-agregar-evento{
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.no-events{
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.options-menu-events {
  background-color: white;
  display: none;
  position: absolute;
  box-shadow: 3px 3px 9px #e9aee8;
  top: 100%;
  right: 3.5%;
}