h4{
  font-family: Montserrat-Medium;
  font-size: 22px;
  color: #5C068C;
}

.mis-preferencias{
  position:relative;
}

.medal-btn {
  position: absolute;
  top: -40%;
  right: -12%;
  width: 25px;
  height: 25px;
  background-color: #bd007e;
  border-radius: 50%;
  color: white;
  font-size: 15px;
  font-family: Monserrat-Bold;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}