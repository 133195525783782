.share-wish-footer-box {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #9e3995;
  color: #fff;
  text-align: center;
  padding-bottom: 1rem;
}
.share-wish-footer-box2 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  color: #9e3995;
  text-align: center;
  padding-bottom: 1rem;
}
.share-footer-box {
  width: 100%;
  border-top: 1px solid #f0f0f0;
  background-color: #9e3995;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  z-index: 100;
}
.first-footer-section {
  background-color: #9e3995;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.second-footer-section {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.share-footer {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.links-stores {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}
.join-giftoky {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
}
.join-giftoky2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}
.bold-text {
  font-weight: bolder;
  font-size: 18px;
}
.bold-text2 {
  font-weight: bolder;
  font-size: 25px;
}
.normal-text2 {
  font-size: 20px;
}
.space {
  height: 20px;
}
.space2 {
  height: 40px;
}
.logo-giftoky-footer {
  width: 100%;
  height: auto;
  max-width: 400px;
}
.store-img {
  width: 100%;
  max-width: 200px;
  height: auto;
}
.final-footer-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.key-app-words {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

@media screen and (max-width: 820px) {
  .store-img {
    max-width: 150px;
  }
  .logo-giftoky-footer {
    max-width: 300px;
  }
  .join-giftoky,
  .join-giftoky2 {
    padding: 1rem;
  }
}
