.agregar-fotos{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom:1.5rem;
}

.font-medium{
  font-size: 16px;
  font-family: Montserrat-Light;
  color: #981E97;
  padding: 10px 0 0 0px;
  margin: 0;
  font-weight: bold;
}

.checklist-agregar-deseo{
  display:flex;
  justify-content: flex-start;
  margin-left:0;
}

.text-checkbox{
  margin:2px 0 0 1%;
}

.save{
  background-color: var(--purple-giftoky);
  color: #fff;
  border-radius: .3rem;
  max-width:150px;
  margin: 1rem 0;
  padding:.5rem 1rem;
  font-family: Montserrat-Medium
}

.save:hover{
  color: var(--purple-giftoky);
  background-color: var(--yellow-giftoky);
  border-radius: .3rem;
  max-width:150px;
  margin: 1rem 0;
  padding:.5rem 1rem;
}