.tarjeta-mi-perfil {
  display: flex;
  flex-direction: row;
  width: 97%;
  margin: 30px auto;
  box-shadow: 3px 3px 9px #ccc;
  border: 1px solid #ccc;
  background-image: url(../../assets/images/fondo-morado.jpg);
  background-repeat: no-repeat;
  padding: 20px 0 20px 20px;
  border-radius: 20px;
  color: white;
  height: 150px;
  max-width: 1600px;
}

.nombre-tarjeta {
  font-family: Montserrat-Medium;
  font-size: 20px;
  color: #fff;
  padding: 0px 10px 0 0;
  margin: 0;
}

.p-tarjeta {
  font-family: Montserrat-Light;
  font-size: 15px;
  color: #fff;
  padding: 0px 10px 0 0;
  margin: 0;
}

.foto-perfil {
  border-radius: 20px;
  width: auto;
  max-height: 100px;
}

@media screen and (max-width: 820px) {
  .foto-perfil {
    max-height: 150px;
  }
  .tarjeta-mi-perfil {
    height: auto;
  }
}
