.send-friend-request {
  background-color: var(--purple-giftoky);
  color: #fff;
  border-radius: 0.3rem;
  max-width: 159px;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  font-family: Montserrat-Medium;
}

.send-friend-request:hover {
  color: var(--purple-giftoky);
  background-color: var(--yellow-giftoky);
  border-radius: 0.3rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
}
