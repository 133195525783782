.navbar-public-box {
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  background-color: #9e3995;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}
.navbar-public {
  position: sticky;
  top: 0px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.rr-ss {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
