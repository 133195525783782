.save-profile {
  background-color: var(--purple-giftoky);
  color: #fff;
  border-radius: .3rem;
  max-width: 175px;
  margin: 1rem 0;
  padding: .5rem 1rem;
  font-family: Montserrat-Medium
}

.save-profile:hover {
  color: var(--purple-giftoky);
  background-color: var(--yellow-giftoky);
  border-radius: .3rem;
  max-width: 175px;
  margin: 1rem 0;
  padding: .5rem 1rem;
}