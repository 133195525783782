.side-nav-bar {
  background: url(../../assets/images/side-nav-bar.jpg) no-repeat;
  height:100vh;
  color: white;
  position: fixed;
  top:64px;
  flex-basis: calc(100%/6);
  width: calc(100%/6);
}
/* position fixed para el sidebar */

.side-nav-item {
  padding: .7rem
}

a {
  text-decoration: none;
  color:white;
}

a:hover {
  font-weight: bold;
  color:white;
}

.side-ad{
 padding:0;
}

.ancho-publicidad{
  width:80%;
  padding:0;
}