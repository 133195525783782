.carrusel-background{
  background-color: black;
  top: 0;
  left: 0;
  opacity: .9;
  position:fixed;
  width:100vw;
  height:100vh;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titulo-onboarding{
  margin-top:50px;
  height: 192px;
}

.box-carrusel{
  background-color: white;
  opacity: 1;
  width: 600px;
  height: 800px;
}

.texto-onboarding{
  height:280px;
  display:flex;
  align-items: center;
}

.paginador-container{
  display: flex;
  justify-content: center;
  height: 30px;
}

.marcador-pagina{
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: var(--purple-giftoky);
  margin: 2rem .3rem;
}

.marcador-pagina:hover{
  opacity:.3;
}

.logo-onboarding{
  width: 80%;
  height: 120px;
}