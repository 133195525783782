.ancho-side-bar{
  flex-basis: calc(100%/6)
}
.ancho-content{
  flex-basis: calc(100%/6*4)
}
.ancho-calendario{
  flex-basis: calc(100%/6)
}

.img-publicidad{
  width:100%;
  /* max-height:200.5px; */
}