h4{
  font-family: Montserrat-Medium;
  font-size: 22px;
  color: #5C068C;
}

.mis-preferencias {
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #981E97;
  color: #981E97;
}

.titulo-mi-perfil{
	font-family: Montserrat-Medium;
	font-size: 22px;
	color: #5C068C;
}

.preferencias-reg{
	font-size: 12px;
  margin-left: 20px;
	background-color: #fff;
	border: 1px solid #981E97;
	color: #981E97;
	border-radius: 5px;
}

.preferencias-reg:hover{
	font-size: 12px;
  margin-left: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	color: #981E97;
	border-radius: 5px;
}

.img-mail{
  border-radius:20px;
}