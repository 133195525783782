.tarjeta-mis-amigos{
  display: flex;
	flex-direction: row;
	width: 97%;
	margin: 20px auto;
	box-shadow:  3px 3px 9px #ccc;
	border: 1px solid #ccc;
	border-radius: 20px;
  cursor: pointer;
}

.tarjeta-mis-amigos:hover{
  display: flex;
	flex-direction: row;
	width: 97%;
	margin: 20px auto;
	box-shadow:  3px 3px 9px #ccc;
	border: 1px solid #ccc;
	border-radius: 20px;
  cursor: pointer;
  background-color: #ededed;
}

.p-nombre-amigo{
  font-family: Montserrat-Medium;
	font-size: 18px;
	color: #981E97;
	padding: 0 0 0 10px;
	margin-bottom:5px;
}

.p-tarjeta-amigo{
	font-family: Montserrat-Light;
	font-size: 13px;
	color: #981E97;
	padding: 0 10px 0 10px;
	margin: 0;
}