.tarjeta-regalo-landing {
  height: 200px;
  width: 100%;
}
.share-box {
  max-width: 1200px;
  margin: 0 auto;
}
.share-title {
  width: 100%;
}
.share-datos-perfil-box {
  width: 100%;
  display: flex;
  padding: 1rem 0;
}
.share-datos-perfil-box > * {
  width: 100%;
}
.share-foto-perfil {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
.share-datos-perfil {
  margin: auto 0;
}
.share-datos-perfil p {
  color: var(--purple-giftoky);
  padding: 0 1rem;
}
.skeleton-wish-card-container {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
.skeleton-wish-card-container > * {
  width: 30%;
}

@media screen and (max-width: 820px) {
  .tarjeta-regalo-landing {
    height: auto;
  }

  .row {
    gap: 1rem;
  }
  .skeleton-wish-card-container {
    flex-direction: column;
  }
  .skeleton-wish-card-container > * {
    width: 100%;
  }
  .cards-row {
    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  .share-datos-perfil-box {
    flex-direction: column;
    gap: 1rem;
  }
}
