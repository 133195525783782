h4 {
  font-family: Montserrat-Medium;
  font-size: 22px;
  color: #5c068c;
}

.header-mis-deseos {
  position: relative;
}

.mis-preferencias {
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #981e97;
  color: #981e97;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.mis-preferencias:hover {
  font-size: 12px;
  background-color: #ededed;
  border: 1px solid #981e97;
  color: #981e97;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.header {
  margin-bottom: 50px;
}

.options-menu {
  background-color: white;
  display: none;
  position: absolute;
  box-shadow: 3px 3px 9px #e9aee8;
  top: 100%;
}

.option-item {
  padding: 0.5rem 1rem;
  z-index: 10000000;
}

.option-item:hover {
  cursor: pointer;
  background-color: var(--yellow-giftoky);
}
