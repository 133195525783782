.search-container{
  position: absolute;
  box-shadow: 3px 3px 9px #ccc;
  width: 100%;
  background-color: white;
  z-index: 1000000000000000000000000000000000000000000000000000000;
  height: 200px;
  overflow-y: scroll;
}

.separator-line{
  border-bottom: 1px solid #ccc;
}

.search-result{
  padding:1rem;
}

.search-result:hover{
  cursor: pointer;
  background-color: #ededed;
}