.message-title {
  font-family: Montserrat-Medium;
  font-size: 18px;
  color: #981E97;
  padding: 0 0 0 10px;
  margin: 5px 0 0 0;
}

.tarjeta-prox-evento:hover{
  background-color: #f0f0f0;
}