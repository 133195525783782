.img-login{
  flex-basis:50%;
  width:100%;
}

.img-bg-login{
  width:100%;
  height: 100%;
}

.form-container-login{
  flex-basis:50%;
}

.form-login{
  display:flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.ancho-login{
  width:70%;
}

.remember-text-login{
  margin-top:.1rem
}

.remember-check-login{
  margin-top: .5rem;
  margin-bottom: 2rem!important;
}

.input-box{
  height:50px;
}

.logo-login{
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.links-login{
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.forgot-password, .register{
  margin-top:1.3rem;
}
.forgot-password:hover, .register:hover{
  opacity:0.5;
}
.alternate-login-button{
  margin-top: 3rem;
  justify-content:space-around;
}
/* #4c69ba */
.facebook-button{
  background-color: #4c69ba;
  border: none;
  color: white;
  padding: .5rem 1rem;
  border-radius: .3rem;
}

.facebook-button:hover{
  opacity: .7;
}

@media screen and (max-width: 1200px){
  .alternate-login-button{
    flex-direction:column;
    gap: .5rem;
  }
}

@media screen and (max-width: 820px){
  .img-login {
    display: none;
  }

  .form-container-login {
    flex-basis: 100%;
  }
}