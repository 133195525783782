:root{
  --purple-giftoky: #981E97;
  --yellow-giftoky: #FFD100;
}

@font-face {
  font-family: Montserrat-Bold ;
  src: url(../fonts/Montserrat-Bold.otf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../fonts/Montserrat-Medium.otf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.otf);
}


@font-face {
  font-family: Montserrat-Light;
  src: url(../fonts/Montserrat-Light.otf);
}


body{
  font-family: Montserrat-light!important;
  color: var(--purple-giftoky)
}

.btn-giftoky{
  background-color: var(--purple-giftoky);
  color:white;
  border: 0;
  border-radius: .5rem;
  padding: .5rem 0;
  height:50px;
  min-width:20%
}

.btn-giftoky:hover{
  background-color: var(--yellow-giftoky);
  color: var(--purple-giftoky);
}