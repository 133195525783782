h3{
  font-family:Montserrat-Regular;
  margin:3rem 0;
}

h2{
  font-family: Montserrat-Medium;
  margin-bottom:3rem;
}

.p-policy{
  padding:0 2rem;
  color:#999;
  text-align: justify;
}