.calendario, .top{
	width: 100%;
	transition: .5s;
}
.top{
	overflow-y: scroll;
	height: calc(100vh - 369px);
	overflow-x: hidden;
	transition: .5s;
}

.ancho-calendario{
	box-shadow: 3px 3px 9px #e9aee8;
	height: 100%;
	max-height: calc(100vh - 64px);
	position: fixed;
	width: calc(100%/6);
	right:0;
}

.foto-calendario {
  max-width:100%;
}

/* .calendario > .recomendaciones{
	height: 100%;
} */

.recomendaciones{
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	/* height:100vh; */
}

.box-recomendacion{
  flex-basis: 40%;

}

.bloque-recomendacion{
  padding:0!important;
	flex-basis: 50%;
	width: 100%;
}

.img-recomendacion{
	margin: 0;
	border-radius: 15px;
	box-shadow:  3px 3px 9px #e9aee8;
	margin: 0 0 15px 0;
  width:85%;
	cursor: pointer;
	transition: .3s
}

.img-recomendacion:hover{
	transform:scale(1.1)
}

.recomendation-container{
	height:100%;
}