.share-wish-card-box {
  box-shadow: 3px 3px 9px #c0c0c0;
  /* height: 100%;
  min-height: 350px; */
  border-radius: 15px;
  margin: 1rem;
  /* width: 30%; */
  padding: 0;
  max-width: 357px;
  height: 370px;
}

.share-wish-card-img {
  width: 100%;
  height: 240px;
  object-fit: contain;
  border-radius: 15px 15px 0 0;
}
.share-wish-card-title {
  padding: 1rem;
}
.share-wish-card-price {
  padding: 0 1rem 1rem 1rem;
}

/* @media screen and (min-width: 821px) and (max-width: 1220px) {
  .share-wish-card-box {
    width: 45%;
  }
}
@media screen and (max-width: 820px) {
  .share-wish-card-box {
    width: 100%;
  }
} */
