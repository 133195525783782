.nav-item:hover {
  background-color:  #ffd136!important;
}

.nav-bar {
  position:sticky;
  top:0px;
  background-color:white;
  z-index:1000000;
}

.ancho-nav-bar{
  flex-basis:calc(100%/6);
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.medal-container{
  position:relative
}

.medal{
  position:absolute;
  top: -39%;
  right: 1%;
  width: 18px;
  height: 18px;
  background-color: #bd007e;
  border-radius: 50%;
  color:white;
  font-size: 10px;
  font-family: Monserrat-Bold;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}