.filete {
  border-top: 1px solid #981e97;
  height: 50px;
  width: 97%;
  margin: 0 auto;
}

.form-check-input:checked {
  background-color: #ffd100 !important;
  border: 0;
  width: 20px;
  height: 20px;
}

.form-check-input {
  background-color: #ffd100 !important;
  border: 0;
  width: 20px;
  height: 20px;
}

.wish-item-list {
  box-shadow: 3px 3px 9px #ccc;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 1600px;
}

.wish-item-list:hover {
  background-color: #ededed;
  cursor: pointer;
}
