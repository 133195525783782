.tarjeta-prox-evento{
	display: flex;
	flex-direction: row;
	width: 97%;
	margin: 20px auto;
	box-shadow:  3px 3px 9px #ccc;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 10px;
}

.nombre-prox-evento{
  font-family: Montserrat-Medium;
	font-size: 18px;
	color: #981E97;
	padding: 0 0 0 10px;
	margin-bottom:5px;
}

.p-prox-evento{
	font-family: Montserrat-Light;
	font-size: 13px;
	color: #981E97;
	padding: 0 10px 0 10px;
	margin: 0;
}

.event-item:hover{
	background-color: #ededed;
	cursor: pointer;
}